import { ChainId, Currency, WNATIVE } from '@nevellusdex/core-sdk'
import useHttpLocations from 'app/hooks/useHttpLocations'
import { WrappedTokenInfo } from 'app/state/lists/wrappedTokenInfo'
import React, { FunctionComponent, useMemo } from 'react'

import Logo, { UNKNOWN_ICON } from '../Logo'

const BLOCKCHAIN = {
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.GÖRLI]: 'goerli',
  // [ChainId.BSC]: 'bsc',
  // [ChainId.CELO]: 'celo',
  // [ChainId.FANTOM]: 'fantom',
  // [ChainId.AVALANCHE_TESTNET]: 'fuji',
  [ChainId.FUSE]: 'fuse',
  // [ChainId.HARMONY]: 'harmony',
  // [ChainId.HECO]: 'heco',
  // [ChainId.MATIC]: 'matic',
  // [ChainId.MOONRIVER]: 'moonriver',
  // [ChainId.OKEX]: 'okex',
  // [ChainId.PALM]: 'palm',
  // [ChainId.TELOS]: 'telos',
  // [ChainId.XDAI]: 'xdai',
  // [ChainId.ARBITRUM]: 'arbitrum',
  // [ChainId.AVALANCHE]: 'avalanche',
  // [ChainId.MOONBEAM]: 'moonbeam',
  // [ChainId.KAVA]: 'kava',
  // [ChainId.METIS]: 'metis',
  // [ChainId.ARBITRUM_NOVA]: 'arbitrum-nova',
  // [ChainId.HARDHAT]: 'hardhat',
}

// @ts-ignore TYPE NEEDS FIXING
export const getCurrencyLogoUrls = (currency: Currency): string[] => {
  const urls: string[] = []

  if (currency.chainId in BLOCKCHAIN) {
    urls.push(
      `https://raw.githubusercontent.com/nevellusdex/list/master/logos/token-logos/network/${
        // @ts-ignore TYPE NEEDS FIXING
        BLOCKCHAIN[currency.chainId]
      }/${currency.wrapped.address}.jpg`
    )
    urls.push(
      // @ts-ignore TYPE NEEDS FIXING
      `https://raw.githubusercontent.com/nevellusdex/assets/master/blockchains/${BLOCKCHAIN[currency.chainId]}/assets/${
        currency.wrapped.address
      }/logo.png`
    )
    urls.push(
      // @ts-ignore TYPE NEEDS FIXING
      `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${BLOCKCHAIN[currency.chainId]}/assets/${
        currency.wrapped.address
      }/logo.png`
    )
  }
  return urls
}

// const AvaxLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/avax.svg'
// const BnbLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/bnb.svg'
const EthereumLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/ethereum.svg'
// const FtmLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/ftm.svg'
// const OneLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/one.svg'
// const HtLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/ht.svg'
const MaticLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/matic.svg'
// const GlmrLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/glmr.svg'
// const OktLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/okt.svg'
// const xDaiLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/xdai.svg'
// const CeloLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/celo.svg'
// const PalmLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/palm.svg'
// const MovrLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/movr.svg'
const FuseLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/fuse.svg'
// const TelosLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/telos.svg'
// const KavaLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/kava.svg'
// const MetisLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/metis.svg'
// const BobaLogo = 'https://raw.githubusercontent.com/nevellusdex/list/master/logos/native-currency-logos/boba.svg'

const LOGO: Record<number, string> = {
  [ChainId.ETHEREUM]: EthereumLogo,
  [ChainId.GÖRLI]: EthereumLogo,
  // [ChainId.FANTOM]: FtmLogo,
  // [ChainId.FANTOM_TESTNET]: FtmLogo,
  // [ChainId.MATIC]: MaticLogo,
  [ChainId.MATIC_TESTNET]: MaticLogo,
  // [ChainId.XDAI]: xDaiLogo,
  // [ChainId.BSC]: BnbLogo,
  // [ChainId.BSC_TESTNET]: BnbLogo,
  // [ChainId.AVALANCHE]: AvaxLogo,
  // [ChainId.AVALANCHE_TESTNET]: AvaxLogo,
  // [ChainId.HECO]: HtLogo,
  // [ChainId.HECO_TESTNET]: HtLogo,
  // [ChainId.HARMONY]: OneLogo,
  // [ChainId.HARMONY_TESTNET]: OneLogo,
  // [ChainId.OKEX]: OktLogo,
  // [ChainId.OKEX_TESTNET]: OktLogo,
  // [ChainId.ARBITRUM]: EthereumLogo,
  // [ChainId.ARBITRUM_TESTNET]: EthereumLogo,
  // [ChainId.CELO]: CeloLogo,
  // [ChainId.PALM]: PalmLogo,
  // [ChainId.PALM_TESTNET]: PalmLogo,
  // [ChainId.MOONRIVER]: MovrLogo,
  [ChainId.FUSE]: FuseLogo,
  // [ChainId.TELOS]: TelosLogo,
  // [ChainId.HARDHAT]: EthereumLogo,
  // [ChainId.MOONBEAM]: GlmrLogo,
  // [ChainId.OPTIMISM]: EthereumLogo,
  // [ChainId.KAVA]: KavaLogo,
  // [ChainId.ARBITRUM_NOVA]: EthereumLogo,
  // [ChainId.METIS]: MetisLogo,
  // [ChainId.BOBA_AVAX]: BobaLogo,
}

export interface CurrencyLogoProps {
  currency?: Currency
  size?: string | number
  style?: React.CSSProperties
  className?: string
}

const CurrencyLogo: FunctionComponent<CurrencyLogoProps> = ({ currency, size = '24px', className, style }) => {
  const uriLocations = useHttpLocations(
    currency instanceof WrappedTokenInfo ? currency.logoURI || currency.tokenInfo.logoURI : undefined
  )
  const srcs: string[] = useMemo(() => {
    if (currency?.isNative || currency?.equals(WNATIVE[currency.chainId])) {
      // @ts-ignore TYPE NEEDS FIXING
      return [LOGO[currency.chainId], UNKNOWN_ICON]
    }

    if (currency?.isToken) {
      const defaultUrls = [...getCurrencyLogoUrls(currency)]
      // console.log({ defaultUrls })

      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, ...defaultUrls, UNKNOWN_ICON]
      }
      return defaultUrls
    }

    return [UNKNOWN_ICON]
  }, [currency, uriLocations])

  return <Logo srcs={srcs} width={size} height={size} alt={currency?.symbol} className={className} style={style} />
}

export default CurrencyLogo
